import React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const addAstrologer = getBaseUrl() + "admin/addAstrologer";
const getAstrologers = getBaseUrl() + "admin/getAstrologers";
const deleteAstrologer = getBaseUrl() + "admin/deleteAstrologer";
const updateAstrologer = getBaseUrl() + "admin/updateAstrologer";

export const updateAstrologer_api = async (data) => {
  let config = {
    method: "patch",
    url: updateAstrologer,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const clearWaitList_api = async (data) => {
  let config = {
    method: "POST",
    url: getBaseUrl() + "admin/clearWaitLIst",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteAstrologer_api = async (data) => {
  let config = {
    method: "delete",
    url: deleteAstrologer,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addAstrologer_api = async (data) => {
  let config = {
    method: "post",
    url: addAstrologer,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAstrologers_api = async (data) => {
  let config = {
    method: "get",
    url: getAstrologers + `?isOnline=${data.isOnline}&gender=${data.gender}&categories=${data.categories}&skills=${data.skills}&languages=${data.languages}&page=${data.page}&limit=${data.limit}&search=${data.search}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}`, "Content-Type": "application/json" },
    // data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


// wallet
export const getAstrologerTranscation_api = async (data) => {
  let config = {
    method: "get",
    url: getBaseUrl() + "admin/astroTransactionListing" + `?id=${data.id}&page=${data.page}&limit=${data.limit}&search=${data.search}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}`, "Content-Type": "application/json" },
    // data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addAstrologerCredit_api = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl() + "admin/updateAstroWallet",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

// gallery
export const getAstrologerGallery_api = async (data) => {
  let config = {
    method: "get",
    url: getBaseUrl() + "admin/astroGallaryByAstroId" + `?astroId=${data.id}&page=${data.page}&limit=${data.limit}&search=${data.search}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addAstrologerGallery_api = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl() + "admin/addAstroGallary",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteAstrologerGallery_api = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl() + "admin/deleteAstroGallary",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

// Settlement
export const getTransactions = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl() + "admin/getSettlementTxns",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getInvoice = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl() + "admin/getInvoiceData",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const generateInvoice = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl() + "admin/generateInvoice",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateSettlement = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl() + "admin/updateSettlement",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const invoiceListing = async (data) => {
  let page = data.page || 1;
  let config = {
    method: "get",
    url: getBaseUrl() + `admin/getAstroInvoices${data.id ? '/'+data.id : ''}?page=${page}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};