import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";
let local = "http://localhost:5007/"

export const getNews_api = async (data) => {
    let config = {
        method: "get",
        url: getBaseUrl() + "admin/getNews" + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}`, "Content-Type": "application/json" },
        // data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

export const addNews_api = async (data) => {
    let config = {
        method: "post",
        // url: getBaseUrl() + "admin/addNews",
        url: local + "admin/addNews",
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data,
    };

    try {
        return await axios(config);
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

// const getBanners = getBaseUrl() + "admin/getBanners";
// const statusUpdate_banner = getBaseUrl() + "admin/updateBanner";
// const deleteBanner = getBaseUrl() + "admin/deleteBanner";
// const addBanner = getBaseUrl() + "admin/addBanner";
// const updateBanner = getBaseUrl() + "admin/updateBanner";

// export const updateBanner_api = async (data) => {
//   let config = {
//     method: "patch",
//     url: updateBanner,
//     headers: { Authorization: `Bearer ${Cookies.get("token")}` },
//     data: data,
//   };

//   try {
//     let res = await axios(config);
//     return res;
//   } catch (error) {
//     console.log(error);
//     return error.response;
//   }
// };
// export const addBanner_api = async (data) => {
//   let config = {
//     method: "post",
//     url: addBanner,
//     headers: { Authorization: `Bearer ${Cookies.get("token")}` },
//     data: data,
//   };

//   try {
//     let res = await axios(config);
//     return res;
//   } catch (error) {
//     console.log(error);
//     return error.response;
//   }
// };
// export const deleteBanner_api = async (data) => {
//   let config = {
//     method: "delete",
//     url: deleteBanner,
//     headers: { Authorization: `Bearer ${Cookies.get("token")}` },
//     data: data,
//   };

//   try {
//     let res = await axios(config);
//     return res;
//   } catch (error) {
//     console.log(error);
//     return error.response;
//   }
// };
// export const statusUpdate_banner_api = async (data) => {
//   let config = {
//     method: "patch",
//     url: statusUpdate_banner,
//     headers: { Authorization: `Bearer ${Cookies.get("token")}` },
//     data: data,
//   };

//   try {
//     let res = await axios(config);
//     return res;
//   } catch (error) {
//     console.log(error);
//     return error.response;
//   }
// };
// export const getBanners_api = async (data) => {
//   let config = {
//     method: "get",
//     url: getBanners + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
//     headers: { Authorization: `Bearer ${Cookies.get("token")}`, "Content-Type": "application/json" },
//     // data: data,
//   };

//   try {
//     let res = await axios(config);
//     return res;
//   } catch (error) {
//     console.log(error);
//     return error.response;
//   }
// };