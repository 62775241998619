import React, { useState } from "react";
import { RxDashboard } from "react-icons/rx";
import s from "./sidebar.module.css";
import { FiUsers } from "react-icons/fi";
import { MdOutlineArticle, MdEvent } from "react-icons/md";
import { RiCommunityLine } from "react-icons/ri";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { AiFillCopy } from "react-icons/ai";
import { SiOpenbadges } from "react-icons/si";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import logo from "../../assets/icons/logo.svg";
import { useNavigate } from "react-router-dom";
const Sidebar = ({ open }) => {
  const navigate = useNavigate();
  const [menuItems, setmenuItems] = useState([
    {
      title: " Dashboard",
      icons: <RxDashboard size={20} />,
      navigate: "dashboard",
      show: false,
      subItems: [],
    },
    {
      title: " Role",
      icons: <MdOutlineArticle size={20} />,
      navigate: "role",
      show: false,
      subItems: [],
    },
    {
      title: "Admin",
      icons: <MdOutlineAdminPanelSettings size={20} />,
      navigate: "admin",
      show: false,
      subItems: [],
    },
    {
      title: "Astrologer",
      icons: <MdEvent size={20} />,
      navigate: "astrologer-list",
      show: false,
      subItems: [],
    },
    {
      title: "Slabs",
      icons: <AiFillCopy size={20} />,
      navigate: "astrologer-slab",
      show: false,
      subItems: [],
    },
    {
      title: " Users",
      icons: <FiUsers size={20} />,
      navigate: "users",
      show: false,
      subItems: [],
    },

    {
      title: "Skill",
      icons: <MdEvent size={20} />,
      navigate: "skill-list",
      show: false,
      subItems: [],
    },
    {
      title: "Language",
      icons: <MdEvent size={20} />,
      navigate: "language",
      show: false,
      subItems: [],
    },
    {
      title: "Category",
      icons: <MdEvent size={20} />,
      navigate: "category-list",
      show: false,
      subItems: [],
    },
    {
      title: "Banner",
      icons: <MdEvent size={20} />,
      navigate: "banner-list",
      show: false,
      subItems: [],
    },
    {
      title: "Testimonial",
      icons: <MdEvent size={20} />,
      navigate: "testimonial-list",
      show: false,
      subItems: [],
    },
    {
      title: "Offers",
      icons: <MdEvent size={20} />,
      navigate: "offers_list",
      show: false,
      subItems: [],
    },
    {
      title: "News",
      icons: <MdEvent size={20} />,
      navigate: "news_list",
      show: false,
      subItems: [],
    },
    {
      title: "Wallet",
      icons: <MdEvent size={20} />,
      navigate: "wallet",
      show: false,
      subItems: [],
    },
    {
      title: "Settlement",
      icons: <MdEvent size={20} />,
      navigate: "astrologer-invoices",
      show: false,
      subItems: [],
    },
  ]);
  return (
    <>
      <section className={s["sidebar"]}>
        <div className={`${s["collapsed-logo"]} ${open ? `${s["logo-section"]}` : `${s["logo-section-hide"]}`}`}>
          <img src={logo} alt="logo" draggable="false" />
        </div>
        {menuItems.map((data, i) => (
          <div key={'side-'+i} className={s["sidebar-content"]}>
            <div className={s["sidebar-item"]} onClick={() => {
                if(data?.navigate === 'astrologer-invoices'){
                  navigate(`/${data?.navigate}`, {state: {}})
                }else{
                  navigate(`/${data?.navigate}`)
                }
              }}>
              <div className="sidebaricons">{data.icons}</div>
              <div className={open ? `${s["sidebar-title"]}` : `${s["sidebar-title-hide"]}`}>{data.title}</div>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default Sidebar;
