import { Card, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { addOffer_api, updateOffer_api } from "../api/offerAPI";
import s from "./offer.module.css";

const AddOffer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pagetype = location.state.pagetype;
  const slabData = location.state.slabData;
  const { state, dispatch } = useContext(UserContext);
  const [titleValue, setTitleValue] = useState("");
  const [offerTitleValue, setOfferTitleValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [offerDescriptionValue, setOfferDescriptionValue] = useState("");
  const [isOfferActive, setIsOfferActive] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [slabSelect, setSlabSelect] = useState(false);
  const [offerAmt, setofferAmt] = useState(null);
  const [offerType, setofferType] = useState("fixed");
  const [baseAmt, setbaseAmt] = useState("");
  const [offerValidity, setofferValidity] = useState(3);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [selectedUseType, setselectedUseType] = useState("-1");
  const [useCount, setuseCount] = useState("-1");
  const [priority, setpriority] = useState("");
  const [tagValue, setTagValue] = useState("");

  const [error, seterror] = useState({});

  console.log("data",location?.state?.data)

  useEffect(() => {
    setTitleValue(location?.state?.data?.title);
    setOfferTitleValue(location?.state?.data?.offer_title);
    setDescriptionValue(location?.state?.data?.description);
    setOfferDescriptionValue(location?.state?.data?.offer_description);
    setbaseAmt(location?.state?.data?.recharge_amount);
    setofferAmt(location?.state?.data?.cashback_amount);
    setofferType("fixed");
    setofferValidity(3);
    setTagValue(location?.state?.data?.tag);
    setpriority(location?.state?.data?.priority);
    setstartDate(location?.state?.data?.offer_startdate?.slice(0, -2));
    setendDate(location?.state?.data?.offer_enddate?.slice(0, -2));
    setIsOfferActive(location?.state?.data?.has_promo_cashback);
    setIsDefault(location?.state?.data?.is_default);
    setSlabSelect(location?.state?.data?.slab_id);
  }, [location]);

  

  const Category_function = async () => {
    let tempErrors = {};
    if (!titleValue) tempErrors.titleValue = "Title is Required!";
    if (!offerTitleValue)
      tempErrors.offerTitleValue = "Offer Title is Required!";
    if (!descriptionValue)
      tempErrors.descriptionValue = "Description is Required!";
    if (!offerDescriptionValue)
      tempErrors.offerDescriptionValue = "Offer Description is Required!";
    if (!baseAmt) tempErrors.baseAmt = "Base Amount is Required!";
    if (!offerAmt) tempErrors.offerAmt = "Offer Amount is Required!";
    if (!offerType) tempErrors.offerType = "Offer Type is Required!";
    if (!startDate) tempErrors.startDate = "Start Date is Required!";
    if (new Date(startDate) > new Date(endDate))
      tempErrors.startDate = "Start Date Should Be Less Than End Date!";
    if (!endDate) tempErrors.endDate = "End Date is Required!";
    if (!priority) tempErrors.priority = "Priority is Required!";

    seterror(tempErrors);
    if (Object.keys(tempErrors).length) return;

    let tempData = {
      cashbackAmount: offerAmt,
      rechargeAmount: baseAmt,
      offerStartdate: new Date(startDate).toISOString(),
      offerEnddate: new Date(endDate).toISOString(),
      priority: priority,
      title: titleValue,
      offerTitle: offerTitleValue,
      description: descriptionValue,
      offerDescription: offerDescriptionValue,
      hasPromoCashback: isOfferActive,
      isDefault: isDefault,
      slabId: Number(slabSelect),
      adminId: state?.profile?.id,
      tag: tagValue,
    };
    if (pagetype == "Add") {
      let res = await addOffer_api(tempData);
      if (res.data.status) {
        navigate("/offers_list");
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
        console.log("Some Error!");
      }
    }
    if (pagetype == "Edit") {
       
      tempData = { ...tempData, id: location?.state?.data?.id,editSlab:true };
      console.log("=edit",tempData)
      let res = await updateOffer_api(tempData);
      if (res.data.status) {
        navigate("/offers_list");
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
        console.log("Some Error!");
      }
    }
  };

  // const manageTagsFunc = () => {
  //     setTagValue([...tags, tag])
  //     setTagValue("")
  // }
  // const removeTagFunc = (tag) => {
  //     const filtered = tags.filter((oldTag) => oldTag !== tag);
  //     setTagValue(filtered)
  // }

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Title</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    value={titleValue}
                    onChange={(e) => {
                      setTitleValue(e?.target?.value);
                    }}
                  />
                </div>
                {error.baseAmt !== "" && (
                  <div
                    style={{ width: "98%", fontSize: "0.9rem", color: "red" }}
                  >
                    {error.titleValue}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Description</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    value={descriptionValue}
                    onChange={(e) => {
                      setDescriptionValue(e?.target?.value);
                    }}
                  />
                </div>
                {error.baseAmt !== "" && (
                  <div
                    style={{ width: "98%", fontSize: "0.9rem", color: "red" }}
                  >
                    {error.descriptionValue}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Offer Title</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    value={offerTitleValue}
                    onChange={(e) => {
                      setOfferTitleValue(e?.target?.value);
                    }}
                  />
                </div>
                {error.baseAmt !== "" && (
                  <div
                    style={{ width: "98%", fontSize: "0.9rem", color: "red" }}
                  >
                    {error.offerTitleValue}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Offer Description</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    value={offerDescriptionValue}
                    onChange={(e) => {
                      setOfferDescriptionValue(e?.target?.value);
                    }}
                  />
                </div>
                {error.baseAmt !== "" && (
                  <div
                    style={{ width: "98%", fontSize: "0.9rem", color: "red" }}
                  >
                    {error.offerDescriptionValue}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Recharge Amount</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    value={baseAmt}
                    onChange={(e) => {
                      if (isNaN(e.target.value.trim())) return;
                      else if (e.target.value.trim().includes(".")) return;
                      else setbaseAmt(e.target.value);
                    }}
                  />
                </div>
                {error.baseAmt !== "" && (
                  <div
                    style={{ width: "98%", fontSize: "0.9rem", color: "red" }}
                  >
                    {error.baseAmt}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Offer Amount</label>
                <div className="mr-2">
                  <input
                    type="number"
                    className="form-control"
                    value={offerAmt}
                    onChange={(e) => {
                      if (isNaN(e.target.value.trim())) return;
                      else if (e.target.value.trim().includes(".")) return;
                      else setofferAmt(e.target.value);
                    }}
                  />
                </div>
                {error.offerAmt !== "" && (
                  <div
                    style={{ width: "98%", fontSize: "0.9rem", color: "red" }}
                  >
                    {error.offerAmt}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Offer Type</label>
                <div className="mr-2">
                  <div className="form-group">
                    {/* <select value={offerType} onChange={(e) => setofferType(e.target.value)} className="form-control">
                                            <option value="percent">Percentage</option>
                                            <option value="fix">Fixed</option>
                                        </select> */}
                    <input
                      type="text"
                      className="form-control"
                      disabled={true}
                      value="Fixed"
                    />
                  </div>
                </div>
                {error.offerType !== "" && (
                  <div
                    style={{ width: "98%", fontSize: "0.9rem", color: "red" }}
                  >
                    {error.offerType}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Validity (in months)</label>
                <div className="mr-2">
                  {/* <input
                                        type="text"
                                        className="form-control"
                                        value={offerValidity}
                                        onChange={(e) => {
                                            if (isNaN(e.target.value.trim())) return;
                                            else if (e.target.value.trim().includes(".")) return;
                                            else setofferValidity(e.target.value)
                                        }} /> */}

                  <input
                    type="text"
                    className="form-control"
                    disabled={true}
                    value="3"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Start Time</label>
                <div className="mr-2">
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={startDate}
                    onChange={(e) => setstartDate(e.target.value)}
                  />
                </div>
                {error.startDate !== "" && (
                  <div
                    style={{ width: "98%", fontSize: "0.9rem", color: "red" }}
                  >
                    {error.startDate}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">End Time</label>
                <div className="mr-2">
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => setendDate(e.target.value)}
                  />
                </div>
                {error.endDate !== "" && (
                  <div
                    style={{ width: "98%", fontSize: "0.9rem", color: "red" }}
                  >
                    {error.endDate}
                  </div>
                )}
              </div>
            </Grid>
            {selectedUseType === "limited" && (
              <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Use Count</label>
                  <div className="mr-2">
                    <input
                      type="text"
                      className="form-control"
                      value={useCount === "limited" ? "" : useCount}
                      onChange={(e) => {
                        if (isNaN(e.target.value.trim())) return;
                        else if (e.target.value.trim().includes(".")) return;
                        else setuseCount(e.target.value);
                      }}
                    />
                  </div>
                  {error.useCount !== "" && (
                    <div
                      style={{ width: "98%", fontSize: "0.9rem", color: "red" }}
                    >
                      {error.useCount}
                    </div>
                  )}
                </div>
              </Grid>
            )}
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Priority</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    value={priority}
                    onChange={(e) => {
                      if (isNaN(e.target.value.trim())) return;
                      else setpriority(e.target.value);
                    }}
                  />
                </div>
                {error.priority !== "" && (
                  <div
                    style={{ width: "98%", fontSize: "0.9rem", color: "red" }}
                  >
                    {error.priority}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Tag</label>
                <div className="mr-2">
                  <input
                    type="text"
                    className="form-control"
                    value={tagValue}
                    onChange={(e) => setTagValue(e.target.value)}
                    // onKeyUp={(e) => { if (e.key === 13) manageTagsFunc() }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "baseline" }}
              >
                <div className="mr-2">
                  <input
                    type="checkbox"
                    checked={isDefault}
                    onChange={(e) => {
                      setIsDefault(e.target.checked);
                    }}
                  />
                </div>
                <label for="exampleInputEmail1" style={{ width: "100%" }}>
                  Is Default
                </label>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "baseline" }}
              >
                <div className="mr-2">
                  <input
                    type="checkbox"
                    checked={isOfferActive}
                    onChange={(e) => {
                      setIsOfferActive(e.target.checked);
                    }}
                  />
                </div>
                <label for="exampleInputEmail1" style={{ width: "100%" }}>
                  Offer Active
                </label>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "baseline" }}
              >
                <div className="mr-2 w-100">
                  <label
                    for="exampleInputEmail1"
                    style={{
                      width: "100%",
                      marginBottom: "2px",
                      marginTop: "8px",
                    }}
                  >
                    Select Slab
                  </label>
                  {/* <input
                    type="checkbox"
                    checked={firstTimeRecharge}
                    onChange={(e) => {
                      setFirstTimeRecharge(e.target.checked);
                    }}
                  /> */}
                  <select
                    className="form-control"
                    value={slabSelect}
                    onChange={(e) => setSlabSelect(e.target.value)}
                  >
                    <option value={null}>Regular</option>
                    {slabData?.map((data) => {
                      return (
                        <option value={data.id}>
                          Slab upto {data.maxLimit}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* <label for="exampleInputEmail1" style={{ width: "100%" }}>
                  First Recharge Offer
                </label> */}
              </div>
            </Grid>
          </Grid>

          <div
            className={s["form-login-btn"]}
            onClick={() => Category_function()}
          >
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddOffer;
