import React, { useEffect, useState } from "react";
import { Card, CircularProgress, Grid, Box, Tabs, Tab } from "@mui/material";
import s from "./astrologer.module.css";
import {
  getPriceAstrologerSlab_api,
  patchPriceAstrologerSlab_api,
  postPriceAstrologerSlab_api,
} from "../api/astrologerSlab";
import { notificationHandler } from "../../utils/Notification";

const ChatSlabPricing = ({ data, idx, astroId }) => {
  const [chatPriceData, setChatPriceData] = useState([]);
  const [chatMrp, setChatMrp] = useState();
  const [chatSp, setChatSp] = useState();
  const [chatCom, setChatCom] = useState();
  const [captureChange, setCaptureChange] = useState(null);
  // const [typeOfCall, setTypeOfCall] = useState("edit");

  async function postChatPrice(id) {
    const payload = {
      astroId: astroId,
      slabId: id,
      consultType: "chat",
      consultPrice: chatSp,
      consultMrp: chatMrp,
      astroCommission: chatCom,
    };
    try {
      const res = await postPriceAstrologerSlab_api(payload);
      if (res.data.status) {
        notificationHandler({ type: "success", msg: res.data.message });
        getChatPrice(astroId);
        setCaptureChange(null);
      }
      else {
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
    }
  }
  async function updateChatPrice(id) {
    const payload = {
      astroId: astroId,
      slabId: id,
      consultType: "chat",
      consultPrice: priceValueReturn(id)?.price_per_consult,
      consultMrp: chatMrp,
      astroCommission: priceValueReturn(id)?.astro_commission,
    };
    console.log("mrf", id, payload);
    try {
      const res = await patchPriceAstrologerSlab_api(
        payload,
        priceValueReturn(id)?.id
      );
      if (res.data.status) {
        notificationHandler({ type: "success", msg: res.data.message });
        getChatPrice(astroId);
        setCaptureChange(null);
      }
      else {
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
    }
  }
  async function getChatPrice(data) {
    try {
      const res = await getPriceAstrologerSlab_api(data);
      if (res?.data?.status) {
        var chatPricingMrp = [];
        var chatPricingSell = [];
        var chatPricingCom = [];
        setChatMrp(res?.data?.chatMrp);
        setChatPriceData(res?.data?.priceSlabs);

        // if (res?.data?.priceSlabs.length === 0) {
        //   setTypeOfCall("add");
        //   return;
        // }

        // for (let i = 0; i < res?.data?.priceSlabs?.length; i++) {
        //   if (res?.data?.priceSlabs?.[i]?.consult_type === "chat") {
        //     chatPricingMrp.push(
        //       res?.data?.priceSlabs?.[i]?.price_per_consult_mrp
        //     );
        //     chatPricingSell.push(res?.data?.priceSlabs?.[i]?.price_per_consult);
        //     chatPricingCom.push(res?.data?.priceSlabs?.[i]?.astro_commission);
        //   }
        // }
      }
      // setChatMrp(chatPricingMrp);
      // setChatSell(chatPricingSell);
      // setChatCom(chatPricingSell);
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const priceValueReturn = (id) => {
    if (chatPriceData.length == 0) return undefined;
    const matchObject = chatPriceData?.filter((data) => data.slab_id == id);
    // console.log("matchObject", matchObject[0]);
    return matchObject[0];
  };

  const updateFieldObject = (id, key, value) => {
    console.log("hello");
    const updatedData = chatPriceData.map((item) => {
      if (item.slab_id === id) {
        // Create a new object with the updated value for the specified key
        return { ...item, [key]: value };
      }
      return item; // Return the original object if the ID doesn't match
    });

    console.log("item", updatedData);

    // Update the chatPriceData array with the updated data
    setChatPriceData(updatedData);
  };

  useEffect(() => {
    getChatPrice(astroId);
  }, []);
  console.log("chatMrp", chatMrp);
  console.log("allPriceData", chatPriceData);
  // console.log("typeof", data.id,typeOfCall);

  return (
    <Box
      key={idx}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        mt: "10px",
        alignItems: "center",
      }}
    >
      <label
        style={{
          width: "60px",
        }}
        for="exampleInputEmail1"
      >
        Slab upto {data.slab_limit}
      </label>
      <Grid item xs={4}>
        <div className="form-group">
          <label for="exampleInputEmail1">Chat Price MRP INR (Customer)</label>
          <input
            type="text"
            className="form-control"
            placeholder="Chat Price INR"
            name="per_min_chat_mrp"
            disabled
            value={chatMrp}
            // value={
            //   priceValueReturn(data?.id)?.price_per_consult_mrp
            //     ? priceValueReturn(data?.id)?.price_per_consult_mrp
            //     : chatMrp
            // }
            // onChange={(e) => {
            //   if (isNaN(e.target.value.trim())) return;
            //   else if (e.target.value.trim().includes(".")) return;
            //   else {
            //     priceValueReturn(data?.id)?.price_per_consult_mrp || priceValueReturn(data?.id)?.price_per_consult_mrp == "" ?
            //     updateFieldObject(
            //       data?.id,
            //       "price_per_consult_mrp",
            //       e.target.value
            //     ):setChatMrp(e.target.value);
            //   }
            // }}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="form-group">
          <label for="exampleInputEmail1">Chat Price SP (Customer)</label>
          <input
            type="text"
            className="form-control"
            placeholder="Chat Price INR"
            name="per_min_chat"
            value={
              priceValueReturn(data?.id)?.price_per_consult
                ? priceValueReturn(data?.id)?.price_per_consult
                : chatSp
            }
            onChange={(e) => {
              setCaptureChange("change");
              if (isNaN(e.target.value.trim())) return;
              else if (e.target.value.trim().includes(".")) return;
              else {
                priceValueReturn(data?.id)?.price_per_consult ||
                priceValueReturn(data?.id)?.price_per_consult == ""
                  ? updateFieldObject(
                      data?.id,
                      "price_per_consult",
                      e.target.value
                    )
                  : setChatSp(e.target.value);
              }
            }}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="form-group">
          <label for="exampleInputEmail1">
            Astrologer Price INR (Per Minute)
          </label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            name="commision"
            value={
              priceValueReturn(data?.id)?.astro_commission
                ? priceValueReturn(data?.id)?.astro_commission
                : chatCom
            }
            onChange={(e) => {
              setCaptureChange("change");
              if (isNaN(e.target.value.trim())) return;
              else if (e.target.value.trim().includes(".")) return;
              else {
                priceValueReturn(data?.id)?.astro_commission ||
                priceValueReturn(data?.id)?.astro_commission == ""
                  ? updateFieldObject(
                      data?.id,
                      "astro_commission",
                      e.target.value
                    )
                  : setChatCom(e.target.value);
              }
            }}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        {!priceValueReturn(data?.id) ? (
          <button
            onClick={() => postChatPrice(data.id)}
            disabled={captureChange !== "change"}
            className={
              captureChange === "change"
                ? s["pricingFetchButton"]
                : s["pricingFetchButtonBefore"]
            }
          >
            set
          </button>
        ) : (
          <button
            onClick={() => updateChatPrice(data.id)}
            disabled={captureChange !== "change"}
            className={
              captureChange === "change"
                ? s["pricingFetchButton"]
                : s["pricingFetchButtonBefore"]
            }
          >
            update
          </button>
        )}
      </Grid>
    </Box>
  );
};

const CallSlabPricing = ({ data, idx, astroId }) => {
  const [callPriceData, setCallPriceData] = useState([]);
  const [callMrp, setCallMrp] = useState();
  const [callSp, setCallSp] = useState();
  const [callCom, setCallCom] = useState();
  const [captureChange, setCaptureChange] = useState(null);
  // const [typeOfCall, setTypeOfCall] = useState("edit");

  async function updateCallPrice(id) {
    const payload = {
      astroId: astroId,
      slabId: id,
      consultType: "call",
      consultPrice: priceValueReturn(id)?.price_per_consult,
      consultMrp: callMrp,
      astroCommission: priceValueReturn(id)?.astro_commission,
    };
    try {
      const res = await patchPriceAstrologerSlab_api(
        payload,
        priceValueReturn(id)?.id
      );
      if (res.data.status) {
        notificationHandler({ type: "success", msg: res.data.message });
        getCallPrice(astroId);
        setCaptureChange(null);
      } else {
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
    }
  }
  async function postCallPrice(id) {
    const payload = {
      astroId: astroId,
      slabId: id,
      consultType: "call",
      consultPrice: callSp,
      consultMrp: callMrp,
      astroCommission: callCom,
    };
    try {
      const res = await postPriceAstrologerSlab_api(payload);
      if (res.data.status) {
        notificationHandler({ type: "success", msg: res.data.message });
        getCallPrice(astroId);
        setCaptureChange(null);
      }
      else {
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  async function getCallPrice(data) {
    try {
      const res = await getPriceAstrologerSlab_api(data);
      if (res?.data?.status) {
        var callPricingMrp = [];
        var callPricingSell = [];
        var callPricingCom = [];
        setCallMrp(res?.data?.callMrp);
        setCallPriceData(res?.data?.priceSlabs);

        // if (res?.data?.priceSlabs.length === 0) {
        //   setTypeOfCall("add");
        //   return;
        // }

        // for (let i = 0; i < res?.data?.priceSlabs?.length; i++) {
        //   if (res?.data?.priceSlabs?.[i]?.consult_type === "call") {
        //     callPricingMrp.push(
        //       res?.data?.priceSlabs?.[i]?.price_per_consult_mrp
        //     );
        //     callPricingSell.push(res?.data?.priceSlabs?.[i]?.price_per_consult);
        //     callPricingCom.push(res?.data?.priceSlabs?.[i]?.astro_commission);
        //   }
        // }
      }
      // setCallMrp(callPricingMrp);
      // setCallSell(callPricingSell);
      // setCallCom(callPricingSell);
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const priceValueReturn = (id) => {
    if (callPriceData.length == 0) return undefined;
    const matchObject = callPriceData?.filter((data) => data.slab_id == id);
    // console.log("matchObject", matchObject[0]);
    return matchObject[0];
  };

  const updateFieldObject = (id, key, value) => {
    const updatedData = callPriceData.map((item) => {
      if (item.slab_id === id) {
        // Create a new object with the updated value for the specified key
        return { ...item, [key]: value };
      }
      return item; // Return the original object if the ID doesn't match
    });

    // Update the chatPriceData array with the updated data
    setCallPriceData(updatedData);
  };

  useEffect(() => {
    getCallPrice(astroId);
  }, []);
  console.log("callMrp", callMrp);
  console.log("callPriceData", callPriceData);
  return (
    <Box
      key={idx}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        mt: "10px",
        alignItems: "center",
      }}
    >
      <label for="exampleInputEmail1">Slab upto {data.slab_limit}</label>
      <Grid item xs={4}>
        <div className="form-group">
          <label for="exampleInputEmail1">Audio Call MRP INR (Customer)</label>
          <input
            type="text"
            className="form-control"
            placeholder="Audio Call Price INR"
            name="per_min_voice_call_mrp"
            disabled
            value={callMrp}
            // value={
            //   priceValueReturn(data?.id)?.price_per_consult_mrp
            //     ? priceValueReturn(data?.id)?.price_per_consult_mrp
            //     : callMrp
            // }
            // onChange={(e) => {
            //   if (isNaN(e.target.value.trim())) return;
            //   else if (e.target.value.trim().includes(".")) return;
            //   else {
            //     priceValueReturn(data?.id)?.price_per_consult_mrp ||  priceValueReturn(data?.id)?.price_per_consult_mrp == "" ?
            //     updateFieldObject(
            //       data?.id,
            //       "price_per_consult_mrp",
            //       e.target.value
            //     ):setCallMrp(e.target.value)
            //   }
            // }}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="form-group">
          <label for="exampleInputEmail1">
            Audio Call SP per Minute (in INR)
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Audio Call Price INR"
            name="per_min_voice_call"
            value={
              priceValueReturn(data?.id)?.price_per_consult
                ? priceValueReturn(data?.id)?.price_per_consult
                : callSp
            }
            onChange={(e) => {
              setCaptureChange("change");
              if (isNaN(e.target.value.trim())) return;
              else if (e.target.value.trim().includes(".")) return;
              else {
                priceValueReturn(data?.id)?.price_per_consult ||
                priceValueReturn(data?.id)?.price_per_consult == ""
                  ? updateFieldObject(
                      data?.id,
                      "price_per_consult",
                      e.target.value
                    )
                  : setCallSp(e.target.value);
              }
            }}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="form-group">
          <label for="exampleInputEmail1">
            Audio Call Astrologer's Price per Minute (in INR)
          </label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            name="per_min_voice_call_commission"
            value={
              priceValueReturn(data?.id)?.astro_commission
                ? priceValueReturn(data?.id)?.astro_commission
                : callCom
            }
            onChange={(e) => {
              setCaptureChange("change");
              if (isNaN(e.target.value.trim())) return;
              else if (e.target.value.trim().includes(".")) return;
              else {
                priceValueReturn(data?.id)?.astro_commission ||
                priceValueReturn(data?.id)?.astro_commission == ""
                  ? updateFieldObject(
                      data?.id,
                      "astro_commission",
                      e.target.value
                    )
                  : setCallCom(e.target.value);
              }
            }}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        {!priceValueReturn(data?.id) ? (
          <button
            onClick={() => postCallPrice(data.id)}
            className={
              captureChange === "change"
                ? s["pricingFetchButton"]
                : s["pricingFetchButtonBefore"]
            }
          >
            set
          </button>
        ) : (
          <button
            onClick={() => updateCallPrice(data.id)}
            className={
              captureChange === "change"
                ? s["pricingFetchButton"]
                : s["pricingFetchButtonBefore"]
            }
          >
            update
          </button>
        )}
      </Grid>
    </Box>
  );
};

export { ChatSlabPricing, CallSlabPricing };
