import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./astrologer.module.css";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { clearWaitList_api, deleteAstrologer_api, getAstrologers_api, updateAstrologer_api } from "../api/astrologer";
import Select from "react-select";
import { getLanguages_api } from "../api/language";
import { getCategories_api } from "../api/category";
import { fetchAllSkills } from "../api/skill";

const AstrologerList = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [allastrologer, setallastrologer] = useState([]);
  const [pageCount, setpageCount] = useState(parseInt(localStorage?.getItem("astrologerIndex")) || 1);
  const [deleteId, setdeleteId] = useState("");
  const [deletePopup, setdeletePopup] = useState(false);
  const [currentGroup, setcurrentGroup] = useState({});
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  const [waitlistPopup, setwaitlistPopup] = useState(false);

  const [alllanguage, setalllanguage] = useState([]);
  const [languageId, setlanguageId] = useState(null);
  const [allCategories, setallCategories] = useState([]);
  const [categorieId, setcategorieId] = useState(null);
  const [allSkills, setallSkills] = useState([]);
  const [skillsId, setskillsId] = useState(null);


  const [filterData, setfilterData] = useState({
    isOnline: "",
    gender: "",
  })

  useEffect(() => {
    fetchalllistingdataFunc()
    localStorage.removeItem("astrologerOnBoardForm")
  }, []);

  useEffect(() => {
    fetchallastrologerFunc();
  }, [pageCount, search]);

  const filterDataFunc = (e) => {
    setfilterData({ ...filterData, [e.target.name]: e.target.value })
  }

  async function fetchallastrologerFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        isOnline: "",
        gender: "",
        skills: "",
        languages: "",
        categories: "",
        page: pageCount,
        limit: "8",
        search: search.trim(),
      };
      let res = await getAstrologers_api(temp);
      if (res.data.status) {
        setallastrologer(res?.data?.data.astrologers);
        setpageLength(res?.data?.data?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  async function deleteastroFunc() {
    setisLoading(true);
    let temp = {
      id: deleteId,
    };
    try {
      let res = await deleteAstrologer_api(temp);
      if (res.data.status) {
        setisLoading(false);
        setdeletePopup(false);
        fetchallastrologerFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  }

  const astro_status = async (data, type) => {
    setisLoading(true);
    const fd = new FormData();
    fd.append("id", data.id);
    if (type == "chat") {
      fd.append("is_chat_online", data.is_chat_online == true ? false : true);
    }
    else {
      fd.append("is_voice_online", data.is_voice_online == true ? false : true);
    }

    let res = await updateAstrologer_api(fd);
    console.log(res);
    if (res.data.status) {
      setisLoading(false);
      fetchallastrologerFunc();
      notificationHandler({ type: "success", msg: res.data.message });
    } else {
      setisLoading(false);
      console.log("Error")
      notificationHandler({ type: "danger", msg: res.data.message });
    }
  };

  const astro_busy_status = async (data) => {
    let res = await clearWaitList_api({ id: data.id });
    console.log(res);
    if (res.data.status) {
      fetchallastrologerFunc();
      setwaitlistPopup(false)
      notificationHandler({ type: "success", msg: res.data.message });
    } else {
      console.log("Error")
      notificationHandler({ type: "danger", msg: res.data.message });
    }
  };
  // console.log("allastrologer", allastrologer)

  const verification_status = async (data) => {
    setisLoading(true);
    const fd = new FormData();
    fd.append("id", data.id);
    fd.append("is_verified", data.is_verified == true ? false : true);

    let res = await updateAstrologer_api(fd);
    if (res.data.status) {
      setisLoading(false);
      fetchallastrologerFunc();
      notificationHandler({ type: "success", msg: res.data.message });
    } else {
      setisLoading(false);
      notificationHandler({ type: "danger", msg: res.data.message });
    }
  };

  async function fetchalllistingdataFunc() {
    setisLoading(true);
    try {
      const temp = {
        page: 1,
        limit: 9999,
        search: "",
      };
      let res = await getLanguages_api(temp);
      let res2 = await getCategories_api(temp);
      let res3 = await fetchAllSkills(temp);
      if (res.data.status) {
        let language = res?.data?.data.languages.map((item) => {
          item = { value: item.id, label: item.lang_name };
          return item;
        });
        setalllanguage(language);
      }
      if (res2.data.status) {
        let categorie = res2?.data?.data.categories.map((item) => {
          item = { value: item.id, label: item.category_name };
          return item;
        });
        setallCategories(categorie);
      }
      if (res3.data.status) {
        let Skill = res3?.data?.data.skills.map((item) => {
          item = { value: item.id, label: item.skill_name };
          return item;
        });
        setallSkills(Skill);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (selected) => {
    setlanguageId(selected);
  };
  const handleChange2 = (selected) => {
    setcategorieId(selected);
  };
  const handleChange3 = (selected) => {
    setskillsId(selected);
  };

  const filterFunc = async () => {
    let temp = {
      ...filterData,
      skills: skillsId?.map((item) => item?.value)?.toString() || "",
      languages: languageId?.map((item) => item?.value)?.toString() || "",
      categories: categorieId?.map((item) => item?.value)?.toString() || "",
      page: pageCount,
      limit: "8",
      search: search.trim(),
    }

    setisLoading(true);
    let res = await getAstrologers_api(temp);
    if (res.data.status) {
      setallastrologer(res?.data?.data.astrologers);
      setpageLength(res?.data?.data?.totalPages);
      setisLoading(false);
    } else {
      setisLoading(false);
      console.log("status false!");
    }
  }

  return (
    <div className="">
      <div className="beat_heading">
        <div className={s["user-list-heading"]}>
          <div className="user-list-title">
            <h3>Astrologer List </h3>
          </div>
          <div className={s["user-list-search"]}>
            <div className={s["search-box"]}>
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search by name..."
              />
            </div>
            {/* <div className={s["filter-btn"]}>
              <span style={{ paddingRight: "2px" }}>
                <BiFilter size={20} />
              </span>
              Filter
            </div> */}
          </div>
        </div>
        <div className="beat_left">
          <div
            className={s["title"]}
            onClick={() =>
              navigate("/astrologer-add", {
                state: {
                  pagetype: "Add",
                },
              })
            }
          >
            <IoMdAdd /> Astrologer
          </div>
        </div>
      </div>

      {/* <div className={s["filter-container"]}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={3}>
            <div className="form-group">
              <label for="exampleInputEmail1">Skill</label>
              <Select closeMenuOnSelect={false} isMulti options={allSkills} onChange={handleChange3} value={skillsId} />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="form-group">
              <label for="exampleInputEmail1">Category</label>
              <Select closeMenuOnSelect={false} isMulti options={allCategories} onChange={handleChange2} value={categorieId} />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="form-group">
              <label for="exampleInputEmail1">Select Language</label>
              <Select closeMenuOnSelect={false} isMulti options={alllanguage} onChange={handleChange} value={languageId} />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="form-group">
              <label for="exampleInputEmail1">Chat Online</label>
              <select name="isOnline" onChange={filterDataFunc} className="form-control" id="exampleFormControlSelect1">
                <option value="">All</option>
                <option value={true}>Active</option>
                <option value={false}>InActive</option>
              </select>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="form-group">
              <label for="exampleInputEmail1">Gender</label>
              <select name="gender" onChange={filterDataFunc} className="form-control" id="exampleFormControlSelect1">
                <option value="">All</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="others">Others</option>
              </select>
            </div>
          </Grid>
          <Grid item xs={3} style={{ alignSelf: "flex-end" }}>
            <div onClick={() => filterFunc()} className={s["filter_btn"]} >Filter</div>
          </Grid>
        </Grid>
      </div> */}

      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>{ }
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Consultations (Rate Per Minute)</StyledTableCell>
              <StyledTableCell align="center">Wallet (INR)</StyledTableCell>
              <StyledTableCell align="center">Chat Online</StyledTableCell>
              <StyledTableCell align="center">Audio Call Online</StyledTableCell>
              <StyledTableCell align="center">Astrologer Free</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Verified</StyledTableCell>
              <StyledTableCell align="center">Created</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {allastrologer?.map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell >
                  <div style={{ whiteSpace: "nowrap" }} >ID : {row?.astro_id}</div>
                  <div style={{ whiteSpace: "nowrap" }} >Name : {row?.name}</div>
                  <div style={{ whiteSpace: "nowrap" }} >Email :{row?.email}</div>
                  <div style={{ whiteSpace: "nowrap" }} >Number : {row?.phone} </div>
                </StyledTableCell>
                <StyledTableCell>
                  Chat : {row?.per_min_chat} <br />
                  {/* Voice : {row?.per_min_voice_call} <br />
                  Video : {row?.per_min_vedeo_call} */}
                </StyledTableCell>
                <StyledTableCell align="center">{row?.wallet_balance || 0} </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => astro_status(row, "chat")}
                    className={`${row.is_chat_online === true ? s.active_admin : s.inactive_admin}`}
                  >
                    {row.is_chat_online === true ? "Active" : "Inactive"}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => astro_status(row, "voice")}
                    className={`${row.is_voice_online === true ? s.active_admin : s.inactive_admin}`}
                  >
                    {row.is_voice_online === true ? "Active" : "Inactive"}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setcurrentGroup(row);
                      setwaitlistPopup(true)
                    }}
                    className={`${row.is_busy === false ? s.active_admin : s.inactive_admin}`}
                  >
                    {row.is_busy == false ? "Yes" : "No"}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    // onClick={() => banner_status(row)}
                    className={`${row.status === true ? s.active_admin : s.inactive_admin}`}
                  >
                    {row.status === true ? "Active" : "Inactive"}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => verification_status(row)}
                    className={`${row.is_verified === true ? s.active_admin : s.inactive_admin}`}
                  >
                    {row.is_verified === true ? "Verified" : "Not Verified"}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">{row.createdAt?.slice(0, 10)}</StyledTableCell>
                <StyledTableCell align="center">
                  <div className={s["astrologer-action-btn"]} style={{ flexWrap: "wrap" }}  >
                    <span
                      onClick={() =>
                        navigate("/astrologer-add", {
                          state: {
                            pagetype: "Edit",
                            data: row,
                          },
                        })
                      }
                    >
                      Edit
                    </span>
                    <span
                      onClick={() => {
                        setdeletePopup(true);
                        setcurrentGroup(row);
                        setdeleteId(row.id);
                      }}
                    >
                      Delete
                    </span>
                    <span
                      onClick={() => {
                        navigate("/astrologer-wallet", {
                          state: {
                            data: row,
                          },
                        })
                      }}
                    >
                      Wallet
                    </span>
                    <span
                      onClick={() => {
                        navigate("/astrologer-gallery", {
                          state: {
                            data: row,
                          },
                        })
                      }}
                    >
                      Gallery
                    </span>
                    <span
                      onClick={() => {
                        navigate("/astrologer-settlement", {
                          state: {
                            data: row,
                          },
                        })
                      }}
                    >
                      Generate Invoice
                    </span>
                    <span
                      onClick={() => {
                        navigate("/astrologer-invoices", {
                          state: {
                            data: row,
                          },
                        })
                      }}
                    >
                      Invoices
                    </span>
                    {/* <span>Wallet</span>
                    <span>Gallery</span>
                    <span>Rating</span>
                    <span>Report</span> */}
                  </div>
                  {/* <CiEdit
                    onClick={() =>
                      navigate("/add-banner", {
                        state: {
                          pagetype: "Edit",
                          data: row,
                        },
                      })
                    }
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      marginRight: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                  <MdDelete
                    onClick={() => {
                      setdeletePopup(true);
                      setcurrentGroup(row);
                      setdeleteId(row.id);
                    }}
                    style={{ fontSize: "1rem", color: "var(--clr-primary)", cursor: "pointer" }}
                  /> */}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allastrologer?.length <= 0 && <DataNotFound />}
        {allastrologer?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => {setpageCount(value); localStorage.setItem("astrologerIndex",value)}} page={pageCount} />
          </div>
        )}
      </div>

      {/* delete astrologer popup */}
      <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
        <DialogTitle className={s.dialog_title}>
          <div>Do you want to delete {currentGroup.name}?</div>
        </DialogTitle>
        <DialogContent className={s.cardpopup_content}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
              Cancel
            </div>
            <div className={s.employee_gl_popup_del} onClick={() => deleteastroFunc()}>
              Delete
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      {/* waitlist */}
      <Dialog open={waitlistPopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setwaitlistPopup(false)}>
        <DialogTitle className={s.dialog_title}>
          <div>Do you want to free {currentGroup.name}?</div>
          <div>
            <span style={{ marginRight: "0.5rem", color: "red" }}><AiOutlineExclamationCircle /></span>
            <span style={{ fontSize: "0.9rem" }}>All the users from waiting list will be removed!</span>
          </div>
        </DialogTitle>
        <DialogContent className={s.cardpopup_content}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className={s.employee_gl_popup} onClick={() => setwaitlistPopup(false)}>
              Cancel
            </div>
            <div className={s.employee_gl_popup_del} onClick={() => astro_busy_status(currentGroup)}>
              Clear
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Loder loading={isLoading} />
    </div >
  );
};

export default AstrologerList;
