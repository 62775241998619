import React, { useState, useEffect } from "react";
import s from "../Users/user.module.css";
import { BiArrowBack } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addBanner_api, updateBanner_api } from "../api/banner";
import SampleFile from "../../assets/files/BulkCashback_Addition_Withdrawal_Sample.csv"
import { getBonusAmount_api, updateBonusAmount_api, updateWallet_api } from "../api/walletAPI";


const Wallet = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [type, settype] = useState("credit");
    const [file, setfile] = useState(null);
    const [wbonasAmount, setWbonasAmount] = useState()
    const pagetype = location?.state?.pagetype;
    // console.log(location);
    // useEffect(() => {
    //     // if (location.state) {
    //     //     settype(location?.state?.data?.type);
    //     // }
    // }, [location]);
    useEffect(() => {
        getBonusAmountFunc()
    }, []);

    async function getBonusAmountFunc() {
        let res = await getBonusAmount_api();
        if (res.data.status) {
            setWbonasAmount(res.data.data.signup_bonus_amt)
        } else {
            notificationHandler({ type: "danger", msg: res.data.message });
        }
    }

    const updateBonusAmountFunc = async () => {
        let res = await updateBonusAmount_api({ bonusAmt: wbonasAmount });
        if (res.data.status) {
            notificationHandler({ type: "success", msg: res.data.message });
        } else {
            notificationHandler({ type: "danger", msg: "Error!" });
        }
    }

    const walletFunc = async () => {
        console.log(type)

        const fd = new FormData();
        fd.append("type", type);
        fd.append("csv", file);
        let res = await updateWallet_api(fd);
        if (res.data.status) {
            navigate("/users");
            notificationHandler({ type: "success", msg: res.data.message });
        } else {
            notificationHandler({ type: "danger", msg: "Error!" });
            console.log("error");
        }

        // if (pagetype == "Edit") {
        //     const fd = new FormData();
        //     fd.append("id", location?.state?.data?.id);
        //     fd.append("type", type);
        //     fd.append("banner_image", file);
        //     fd.append("url", url);
        //     try {
        //         let res = await updateBanner_api(fd);
        //         if (res.data.status) {
        //             navigate("/banner-list");
        //             notificationHandler({ type: "success", msg: res.data.message });
        //         } else {
        //             notificationHandler({ type: "success", msg: res.data.message });
        //         }
        //     } catch (error) {
        //         notificationHandler({ type: "danger", msg: error.message });
        //         console.log(error);
        //     }
        // }
    };

    return (
        <>
            <div className="">
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3>Wallet</h3>
                    </div>
                    <div className={s["sample_csv_btn"]}>
                        <a href={SampleFile} download>Download Sample File</a>
                    </div>
                </div>
                <Card className={s["admin_container"]} style={{ marginBottom: "1rem" }} >
                    <h5>Welcome Bonus Amount</h5>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1"></label>
                                <div className="mr-2">
                                    <input type="text" className="form-control" value={wbonasAmount} onChange={(e) => setWbonasAmount(e.target.value)} />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1"></label>
                                <div className="mr-2">
                                    <div style={{ width: "25%" }} onClick={updateBonusAmountFunc}>
                                        <Custombutton>SAVE</Custombutton>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Card>
                <Card className={s["admin_container"]}>
                    {/* <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div> */}
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Select Type</label>
                                <div className="mr-2">
                                    <select className="form-control" value={type} onChange={(e) => settype(e.target.value)} >
                                        <option value="credit">Deposit</option>
                                        <option value="debit">Withdraw</option>
                                        <option value="refund">Refund</option>
                                    </select>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Select File</label>
                                <div className="mr-2">
                                    {/* <input type="file" className="form-control" name="img" placeholder="" accept="image/*" /> */}
                                    <input type="file" className="form-control" accept=".csv" onChange={(e) => setfile(e.target.files[0])} />
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <div className={s["form-login-btn"]} onClick={walletFunc}>
                        <Custombutton>Submit</Custombutton>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default Wallet;
