import React, { useState, useEffect } from "react";
import s from "../Astrologer/astrologer.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addAstrologerCredit_api, getAstrologerTranscation_api } from "../api/astrologer";
const AstrologerAddWallet = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [paymentType, setpaymentType] = useState("credit")
    const [amount, setamount] = useState("")
    const [note, setnote] = useState("")
    console.log("location.state", location.state)

    const pagetype = location?.state?.pagetype;
    useEffect(() => {
        // settype(location?.state?.data?.type);
    }, [location]);

    const Category_function = async () => {
        let tempData = {
            astroId: location.state?.data?.data?.id,
            amount: Number(amount),
            transType: paymentType
        }
        // console.log(tempData)

        if (pagetype == "Add") {
            try {
                let res = await addAstrologerCredit_api(tempData);
                if (res.data.status) {
                    navigate("/astrologer-list");
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }
        // if (pagetype == "Edit") {
        //     try {
        //         let res = await updateBanner_api(fd);
        //         if (res.data.status) {
        //             navigate("/banner-list");
        //             notificationHandler({ type: "success", msg: res.data.message });
        //         } else {
        //             notificationHandler({ type: "success", msg: res.data.message });
        //         }
        //     } catch (error) {
        //         notificationHandler({ type: "danger", msg: error.message });
        //         console.log(error);
        //     }
        // }
    };

    return (
        <>
            <div className="">
                <Card className={s["admin_container"]}>
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Payment Type</label>
                                <select className="form-control" value={paymentType} onChange={(e) => setpaymentType(e.target.value)}>
                                    <option value="credit">Deposit</option>
                                    <option value="debit">Withdraw</option>
                                    <option value="refund">Refund</option>
                                </select>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Amount</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={amount}
                                    onChange={(e) => setamount(e.target.value)}
                                />
                            </div>
                        </Grid>
                        {/* <Grid item xs={6}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Note</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={note}
                                    onChange={(e) => setnote(e.target.value)}
                                />
                            </div>
                        </Grid> */}
                    </Grid>

                    <div className={s["form-login-btn"]} onClick={() => Category_function()}>
                        <Custombutton>Submit</Custombutton>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default AstrologerAddWallet;