import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "../Astrologer/astrologer.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import { deleteBanner_api, getBanners_api, statusUpdate_banner_api } from "../api/banner";
import { deleteAstrologer_api, getAstrologers_api } from "../api/astrologer";
import { getUserTranscation_api } from "../api/user";

const UserWallet = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [isLoading, setisLoading] = useState(false);
    const [allastrologer, setallastrologer] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState("");
    const [deletePopup, setdeletePopup] = useState(false);
    const [currentGroup, setcurrentGroup] = useState({});
    const [pageLength, setpageLength] = useState();
    const [search, setsearch] = useState("");
    console.log("location.state", location.state)
    useEffect(() => {
        fetchallastrologerFunc();
    }, [pageCount, search]);

    async function fetchallastrologerFunc(data) {
        setisLoading(true);
        try {
            const temp = {
                id: location.state?.data?.id,
                page: pageCount,
                limit: 8,
                search: search.trim(),
            };
            let res = await getUserTranscation_api(temp);
            if (res.data.status) {
                setallastrologer(res?.data?.data.Transactions);
                setpageLength(res?.data?.data?.totalPages);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));

    async function deleteastroFunc() {
        setisLoading(true);
        let temp = {
            id: deleteId,
        };
        try {
            let res = await deleteAstrologer_api(temp);
            if (res.data.status) {
                setisLoading(false);
                setdeletePopup(false);
                fetchallastrologerFunc();
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                setisLoading(false);
                notificationHandler({ type: "danger", msg: res.data.message });
            }
        } catch (error) {
            console.log(error);
            notificationHandler({ type: "danger", msg: error.message });
        }
    }
    console.log("allastrologer", allastrologer)


    return (
        <div className="">
            <div className="beat_heading">
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3>User Wallet</h3>
                    </div>
                    <div className={s["user-list-search"]}>
                        <div className={s["search-box"]}>
                            <span style={{ paddingRight: "0.5rem" }}>
                                <BiSearch size={23} />
                            </span>
                            <input
                                type="text"
                                spellCheck="false"
                                onChange={(e) => {
                                    setpageCount(1);
                                    setsearch(e.target.value);
                                }}
                                placeholder="Search by name..."
                            />
                        </div>
                        <div className={s["filter-btn"]}>
                            <span style={{ paddingRight: "2px" }}>
                                <BiFilter size={20} />
                            </span>
                            Filter
                        </div>
                    </div>
                </div>
                <div className="beat_left">
                    <div
                        className={s["title"]}
                        onClick={() =>
                            navigate("/user-add-wallet", {
                                state: {
                                    pagetype: "Add",
                                    data: location.state,
                                },
                            })
                        }
                    >
                        <IoMdAdd /> Add
                    </div>
                </div>
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">ID</StyledTableCell>
                            <StyledTableCell align="center">Amount</StyledTableCell>
                            <StyledTableCell align="center">Amount Type</StyledTableCell>
                            <StyledTableCell align="center">Description</StyledTableCell>
                            <StyledTableCell align="center">Status</StyledTableCell>
                            <StyledTableCell align="center">Created</StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {allastrologer?.map((row, index) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                    ID : {row?.id} <br />
                                    {/* Name : {row?.name} <br />
                                    Email :{row?.email}   <br />
                                    Number : {row?.phone} <br /> */}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.ammount}</StyledTableCell>
                                <StyledTableCell align="center">{row.txn_type == "credit" ? "Deposit" : "Withdraw"} </StyledTableCell>
                                <StyledTableCell align="center">{row.description}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.status === "success" ? (
                                        <div className={s.active_admin}>
                                            success
                                        </div>
                                    ) : row.status === "failed" ? (
                                        <div className={s.inactive_admin}>
                                            failed
                                        </div>
                                    ) : (
                                        <div className={s.pending_box_admin}>
                                            pending
                                        </div>
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row?.createdAt?.slice(0, 10)}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                {allastrologer?.length <= 0 && <DataNotFound />}
                {allastrologer?.length > 0 && (
                    <div className={s["pagination"]}>
                        <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
                    </div>
                )}
            </div>

            <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
                <DialogTitle className={s.dialog_title}>
                    <div>Do you want to delete {currentGroup.name}?</div>
                </DialogTitle>
                <DialogContent className={s.cardpopup_content}>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
                            Cancel
                        </div>
                        <div className={s.employee_gl_popup_del} onClick={() => deleteastroFunc()}>
                            Delete
                        </div>
                    </div>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
            <Loder loading={isLoading} />
        </div>
    );
};

export default UserWallet;
